<template>
  <div class="login__wrapper" v-on:keyup.enter="auth">
    <el-card class="login__card" header="Вход">
      <el-form :model="authForm" :rules="formRules" ref="authForm" >
        <el-form-item prop="login">
          <el-input
            placeholder="Логин"
            v-model="authForm.login"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            placeholder="Пароль"
            type="password"
            v-model="authForm.password"
          ></el-input>
        </el-form-item>
        <el-button
          class="mt-2"
          @click="auth"
          type="primary"
          :loading="loading"
        >
          Войти
        </el-button>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { Message } from 'element-ui';

export default {
  /**
   * Компонент страница для авторизации пользователя
   */
  name: 'Login',
  data() {
    return {
      authForm: {
        login: '',
        password: '',
      },
      formRules: {
        login: [
          { required: true, message: 'Введите логин', trigger: 'blur' },
        ],
        password: [
          { required: true, message: 'Введите пароль', trigger: 'blur' },
        ],
      },
      loading: false,
    };
  },
  methods: {
    async auth() {
      /**
       * Функция авторизации
       */
      this.$refs.authForm.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          const err = await this.$store.dispatch('auth', this.authForm);
          if (!err) {
            await this.$router.push('/devices');
          } else {
            Message.error(err);
            this.loading = false;
          }
        } else {
          console.log('error submit!!');
        }
      });
    },
  },
};

</script>

<style lang="scss" scoped>
  .login__wrapper{
    width: 100%;
    height: calc(300px - 100vh);
  }
  .login__card{
    width: 300px;
    height: 300px;
    margin: 0 auto;
    //top: 0;
    //position: relative;
    margin-top: calc(50vh - 250px);
  }
</style>
